const OHO_MEDIA_API_URL = process.env.oho_media_api_url

const end_point = {
  facebook: "https://graph.facebook.com/v20.0",
  user: "user",
  authentication_user: "authentication-user",
  user_accepted_invitation: "user-accepted-invitation",
  business: "business",
  announcement: "announcement",
  member_note: "member-note",
  member: "member",
  member_me: "member/me",
  member_preference: "/member-preference",
  channel: "channel",
  channel_line_get_message_quota: "/channel/line/get-message-quota",
  channel_reorder: "/channel/reorder",
  channel_facebook: "/channel/facebook",
  channel_facebook_disconnect: "/channel/facebook/disconnect",
  channel_line_disconnect: "/channel/line/disconnect",
  connect_line_channel: "/channel/line",
  connect_instagram_channel: "/channel/instagram",
  channel_instagram_disconnect: "/channel/instagram/disconnect",
  channel_instagram_validate: "/channel/instagram/validate",
  channel_tiktok: "/channel/tiktok",
  channel_tiktok_disconnect: "/channel/tiktok/disconnect",
  deactivate_member: "deactivate-member",
  my_member_list: "my-member-list",
  invite: "invite",
  send_invitation_via_email: "send-invitation-via-email",
  authentication_member_login: "authentication-member/login",
  generate_welcome_auto_reply: "generate-welcome-auto-reply-pattern",
  conversations: "conversations",
  instagram_conversations: "conversations/instagram",
  variable: "replace-variable",
  contact_active: "/metrics/contact-active",
  contact_block: "metrics/contact-block",
  metrics_closed_case: "metrics/case-performance/closed-case",
  metrics_created_case: "metrics/case-performance/created-case",
  metrics_daily_closed_case: "metrics/case-performance/daily-closed-case",
  daily_contact_active: "/metrics/daily-contact-active",
  metrics_closed_case_contact: "metrics/case-performance/closed-case-contact",
  metrics_daily_closed_case_contact:
    "metrics/case-performance/daily-closed-case-contact",
  daily_contact_block: "/metrics/daily-contact-block",
  top5_tag: "metrics/top5-tag",
  top5_contact: "metrics/top5-contact",
  contact_type_ratio: "metrics/contact-type-ratio",
  contact_channel_ratio: "metrics/contact-channel-ratio",
  contact_platform_ratio: "metrics/contact-platform-ratio",
  metrics_case_per_member: "metrics/member-performance/closed-case-per-member",
  metrics_case_per_day: "metrics/case-performance/closed-case-per-day",
  metrics_contact_active: "/metrics/contact_active",
  metrics_closed_case_frt_average:
    "metrics/case-performance/closed-case-frt-average",
  metrics_daily_closed_case_frt_average:
    "metrics/case-performance/daily-closed-case-frt-average",
  metrics_closed_case_rt_average:
    "metrics/case-performance/closed-case-rt-average",
  metrics_daily_closed_case_rt_average:
    "metrics/case-performance/daily-closed-case-rt-average",
  metrics_daily_closed_case_fcr_ratio:
    "metrics/case-performance/daily-closed-case-fcr-ratio",
  metrics_closed_case_fcr_ratio:
    "metrics/case-performance/closed-case-fcr-ratio",
  metrics_closed_case: "metrics/case-performance/closed-case",
  metrics_closed_case_proportion:
    "metrics/case-performance/closed-case-proportion",
  metrics_daily_closed_case: "metrics/case-performance/daily-closed-case",
  metrics_tag: "metrics/case-performance/closed-case-tag-proportion",
  metrics_case: "metrics/case-performance/case",
  metrics_hourly_case: "metrics/case-performance/hourly-case",
  metrics_member_proportion: "metrics/member-performance/member-proportion",
  metrics_closed_case_per_member:
    "metrics/member-performance/closed-case-per-member-per-day",
  metrics_daily_closed_case_per_member:
    "metrics/member-performance/daily-closed-case-per-member",
  metrics_active_member: "metrics/member-performance/active-member-per-day",
  metrics_daily_active_member: "metrics/member-performance/daily-active-member",
  metrics_message_per_member:
    "metrics/member-performance/message-per-member-per-day",
  metrics_daily_message_per_member:
    "metrics/member-performance/daily-message-per-member",
  metrics_member_ranking_by_metric:
    "metrics/member-performance/member-ranking-by-metric",
  metrics_members: "metrics/member-performance/member",
  member_reply: "metrics/member-reply",
  arp_reply: "metrics/arp-reply",
  hourly_contact_active: "metrics/hourly-contact-active",
  daily_arp_reply: "metrics/daily-arp-reply",
  top5_arp: "metrics/top5-arp",
  chatbot_diagram_aggregate: "/chatbot-diagram-aggregate",
  contact: "contact",
  contact_search: "contact/search",
  contact_chat_search: "contact/chat/search",
  contact_starred: "contact/starred",
  contact_aggregate: "contact/aggregate",
  contact_chat_aggregate: "contact/chat/aggregate",
  cache_contact_aggregate: "contact/aggregate/cache",
  contact_preset_search: "contact-preset-search",
  contact_preset_search_aggregate: "contact-preset-search/aggregate",
  member_send_message: "member-send-message",
  member_send_message_bulk: "member-send-message/bulk",
  keyword: "keyword",
  file: `${OHO_MEDIA_API_URL}/file`,
  file_upload_link: `${OHO_MEDIA_API_URL}/file/upload-link`,
  media: `${OHO_MEDIA_API_URL}/media`,
  media_aggregate: `${OHO_MEDIA_API_URL}/media/aggregate`,
  media_reupload: `${OHO_MEDIA_API_URL}/media/reupload`,
  auto_reply_pattern: "auto-reply-pattern",
  remove_auto_reply: "auto-reply-pattern/remove",
  auto_reply_trigger: "auto-reply-trigger",
  auto_reply_pattern_template: "auto-reply-pattern-template",
  change_arp_status: "auto-reply-pattern/change-status",
  connect_facebook_channel: "channel/facebook",
  validate_fb_page_id: "channel/facebook/validate",
  inform_message: "inform-message",
  saved_reply: "saved-reply",
  business_subscription: "/business-subscription",
  subscription_plan: "/subscription-plan",
  subscription_discount: "/subscription-discount",
  business_subscription_monthly_quota: "/business-subscription-monthly-quota",
  business_subscription_monthly_quota_validate:
    "/business-subscription-monthly-quota/validate-choose-plan",
  saved_reply_reorder: "saved-reply/reorder",
  line_message_quota: "line/get-message-quota",
  reissue_page_token: "/business/reissue-facebook-page-token",
  persistent_menu: "/integration/facebook/persistent-menu",
  rich_menu: "/integration/line/rich-menu",
  export_contact: "export/contact",
  comment_reply_pattern: "comment-reply-pattern",
  remove_comment_reply_pattern: "comment-reply-pattern/remove",
  check_facebook_page_permission: "check-facebook-page-permission",
  replace_message_with_variable: "replace-message-with-variable",
  channel_fee_calculator: "/channel-fee-calculator",
  active_case: "active-case",
  archived_case: "archived-case",
  case_preset_search: "case-preset-search",
  case_aggregate: "case/aggregate",
  case_preset_aggregate: "case-preset-search/aggregate",
  contact_activity: "/contact-activity",
  contact_attribute: "contact-attribute",
  team: "/team",
  team_reorder: "/team/reorder",
  deactivate_team: "/deactivate-team",
  assignee_member: "/assignee-member",
  auto_assign_team_setting: "/auto-assign-setting/team",
  auto_assign_chat_status_setting: "/auto-assign-setting/chat-status",
  online_status_aggregate: "/online-status/aggregate",
  auto_assign_aggregate: "/auto-assign/aggregate",
  broadcast: "broadcast",
  ordered_contact_profile: "/ordered-item/profile_panel",
}

export default { ...end_point }
