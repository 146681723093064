
import _ from "lodash"
import ep from "~/api/endpoint"
import { mapState, mapMutations, mapGetters } from "vuex"
import prettyBytes from "pretty-bytes"
import Vue from "vue"
import Clipboard from "v-clipboard"

Vue.use(Clipboard)

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    case_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show_dialog: false,
      show_dialog_media_library: false,
      copy_clipboard: false,
      comment: "",
      is_comment_input_disabled: false,
      loading: false,
      loading_case_activity: false,
      case_detail: {},
      contact_activity_list: [],
      modal_expand_file: null,
      tag_edit: false,
      form: {
        name: "",
        description: "",
        files: [],
      },
      selected_medias: [],
      max_files_per_case: 5,
      upload_method: "",
      media_library_loading: false,
      uploading: false,
      upload_dialog: false,
      upload_method_options: [
        {
          value: "media library",
          label: "คลังสื่อ",
          src: require("~/assets/images/menu_icon_media_library_outline_20px.svg"),
          data_tracking_id:
            "setting_saved-reply_edit_add_img-file_media-libary_click",
        },
        {
          value: "computer",
          label: "จากคอมพิวเตอร์",
          icon: "desktop_mac",
          data_tracking_id:
            "setting_saved-reply_edit_add_img-file_computer_click",
        },
      ],
      media: {
        selected_values: [],
        page: 1,
        total: 0,
        limit: 20,
        data: [],
      },
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.copy_clipboard = false
          this.getCaseDetail()
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      oho_member: (state) => state.oho_member.member || {},
      accept_file_types: (state) => state.accept_file_types,
      checked_channels: (state) => state.checked_channels,
      case_list_current_contact: (state) => state.case_list_current_contact,
      current_contact: (state) => state.smartchat.current_contact,
    }),
    ...mapGetters(["base_path"]),
    case_comment_disabled() {
      return this.loading_case_activity || this.is_comment_input_disabled
    },
    case_response_time() {
      return _.get(this.case_detail, "response_time_start_at")
    },
    case_resolution_time() {
      return _.get(this.case_detail, "resolution_time_start_at")
    },
    case_created_at() {
      return _.get(this.case_detail, "created_at")
    },
    case_response_time_millisecond() {
      return _.get(this.case_detail, "response_time_millisecond")
    },
    case_resolution_time_millisecond() {
      return _.get(this.case_detail, "resolution_time_millisecond")
    },
    case_closed_at() {
      return _.get(this.case_detail, "closed_at")
    },
    assignee_member() {
      return _.get(this.case_detail, "assignee_member.member_id") || {}
    },
    assignee_team() {
      return _.get(this.case_detail, "assignee_member.team_id") || {}
    },
    case_involved_member() {
      return _.get(this.case_detail, "involved_members") || []
    },
    involved_member() {
      const member_list = _.cloneDeep(this.case_involved_member) || []
      let other_member = []
      let remove_duplicate_member = [
        ...new Map(
          member_list.map((item) => [item.member_id._id, item])
        ).values(),
      ]

      if (remove_duplicate_member.length > 0) {
        other_member = remove_duplicate_member.splice(3)
      }

      return {
        member_list: remove_duplicate_member,
        other_member: other_member,
      }
    },
    case_meta_data_update_permission() {
      return this.$permission.validate("case.metadata.update")
    },
    case_tag_add_permission() {
      return this.$permission.validate("case.tag.add")
    },
    case_tag_remove_permission() {
      return this.$permission.validate("case.tag.remove")
    },
    keyword_case_tag_create_permission() {
      return this.$permission.validate("keyword.case-tag.create")
    },
    keyword_case_tag_update_permission() {
      return this.$permission.validate("keyword.case-tag.update")
    },
    keyword_case_tag_remove_permission() {
      return this.$permission.validate("keyword.case-tag.remove")
    },
    media_library_add_permission() {
      return this.$permission.validate("media-library.create")
    },
    my_id() {
      return _.get(this.oho_member, "_id")
    },
    my_role() {
      return _.get(this.oho_member, "role")
    },
    maximum_media_selection() {
      const attachments_count = _.size(this.form.files)
      return this.max_files_per_case - attachments_count
    },
    chat_room_link() {
      const contact_id = _.get(this.case_detail, "contact_id")
      const current_assignee_member_id = _.get(
        this.case_detail,
        "contact.assignee.member_id"
      )

      if (
        this.my_id === current_assignee_member_id ||
        this.my_role === "agent"
      ) {
        return `${this.base_path}/smartchat?status=me&room=${contact_id}`
      } else {
        return `${this.base_path}/smartchat?room=${contact_id}`
      }
    },
    slug() {
      return this.$route?.params?.slug
    },
    is_active_case_page() {
      return this.slug === "inprogress"
    },
    current_active_case() {
      return this.current_contact?.current_case?._id
    },
    is_active_case() {
      return (
        this.current_active_case === this.case_id || this.is_active_case_page
      )
    },
    active_case_param_value() {
      return this.is_active_case ? true : undefined
    },
    case_endpoint() {
      return this.is_active_case ? ep.active_case : ep.archived_case
    },
    is_disabled_copy_link_btn() {
      return this.case_detail?.status === "inprogress"
    },
  },
  methods: {
    ...mapMutations([
      "setShowCaseDialog",
      "setCurrentContact",
      "setCaseListCurrentContact",
    ]),
    handleClose() {
      this.show_dialog = false
      this.setShowCaseDialog(false)
      this.$emit("update:visible", false)
    },
    async getCaseDetail() {
      try {
        this.loading = true

        const case_res = await this.$ohoMemberApi.$get(
          `${this.case_endpoint}/${this.case_id}`,
          {
            params: {
              "$populate[0][path]": "files",
              "$populate[0][select]": [
                "display_name",
                "type",
                "url",
                "icon_url",
                "thumbnail_url",
                "is_media",
              ],
              "$populate[1][path]": "channel_id",
              "$populate[1][select]": ["display_name"],
              "$populate[2][path]": "tags_migrate_objectid",
              "$populate[3][path]": "contact_id",
              "$populate[3][select]": [
                "display_name",
                "status",
                "profile_picture_url",
                "social_profile",
                "assignee",
              ],
              "$populate[4][path]": "assignee_member.member_id",
              "$populate[4][select]": ["display_name", "role", "image_url"],
              "$populate[5][path]": "assignee_member.team_id",
              "$populate[5][select]": [
                "display_name",
                "type",
                "logo_url",
                "color_hex_code",
              ],
              "$populate[6][path]": "involved_members.member_id",
              "$populate[6][select]": ["display_name", "role", "image_url"],
              "$populate[6][populate][0][path]": "teams",
              "$populate[6][populate][0][select]": [
                "display_name",
                "type",
                "logo_url",
                "color_hex_code",
              ],
            },
          }
        )

        const contact_id = _.get(case_res, "contact_id._id")
        this.case_detail = {
          ...case_res,
          contact_id,
          contact: _.get(case_res, "contact_id"),
        }
        this.form = {
          name: case_res.name,
          description: case_res.description,
          files: case_res.files,
        }

        this.loading = false

        if (contact_id) {
          this.getCaseActivity()
        }

        // ดักเพิ่มถ้าเป็นเคสที่ agent ไม่ได้เกี่ยวข้อง จะเปิดไม่ได้
        const involved_member = _.get(case_res, "involved_members") || []
        const involved_members = involved_member.map(
          (item) => item.member_id._id
        )

        if (
          this.my_role === "agent" &&
          !involved_members.includes(this.my_id)
        ) {
          this.show_dialog = false
          this.setShowCaseDialog(false)
        } else {
          this.show_dialog = true
        }
      } catch (error) {
        this.loading = false
        this.setShowCaseDialog(false)
        this.$logger.error(error)
      }
    },
    async getCaseActivity() {
      this.loading_case_activity = true

      try {
        const contact_id = _.get(this.case_detail, "contact_id._id")
        const case_id = _.get(this.case_detail, "_id")

        const activity_res = await this.$ohoMemberApi.$get(
          ep.contact_activity,
          {
            params: {
              contact_id,
              case_id: case_id,
              is_calculate_performance_metric: true,
              $limit: 50,
              $skip: 0,
              "$sort[timestamp]": -1,
              "$sort[_id]": -1,
              "$populate[0][path]": "member_id",
              "$populate[1][path]": "assign_by_member_id",
              "$populate[2][path]": "assign_to_member_id",
              "$populate[3][path]": "action_by_member_id",
              "$populate[4][path]": "assign_by_team_id",
              "$populate[5][path]": "assign_to_team_id",
              "$populate[6][path]": "action_by_team_id",
            },
          }
        )

        this.contact_activity_list = _.get(activity_res, "data")
      } catch (error) {
        this.$logger.error(error)
      }

      this.loading_case_activity = false
    },
    updateCaseListDetail(data) {
      // 1. Update case detail in chat room
      const current_contact_case_id = _.get(
        this.current_contact,
        "current_case._id"
      )

      if (current_contact_case_id === this.case_id) {
        const current_contact = {
          ...this.current_contact,
          current_case: data,
        }
        this.setCurrentContact(current_contact)
      }

      // 2. Update case detail in case list
      const case_index = _.findIndex(this.case_list_current_contact, {
        _id: this.case_id,
      })
      if (case_index >= 0) {
        this.case_list_current_contact[case_index].name = _.get(data, "name")
        this.setCaseListCurrentContact(this.case_list_current_contact)
      }
    },
    updateCaseListTags() {
      const case_index = _.findIndex(this.case_list_current_contact, {
        _id: this.case_id,
      })
      if (case_index >= 0) {
        this.case_list_current_contact[case_index].tags_migrate_objectid =
          this.case_detail.tags_migrate_objectid
        this.setCaseListCurrentContact(this.case_list_current_contact)
      }
    },
    async updateCaseDetail() {
      try {
        const body = {
          ...this.form,
          files: this.form.files.map((file) => file._id),
        }
        const update_res = await this.$ohoMemberApi.$patch(
          `${this.case_endpoint}/${this.case_id}`,
          body,
          {
            params: {
              "$populate[path]": "tags_migrate_objectid",
            },
          }
        )

        if (update_res) {
          this.$emit("change", update_res)

          this.$message({
            message: "บันทึกสำเร็จ",
            type: "success",
          })
          this.updateCaseListDetail(update_res)
          this.show_dialog = false
        }
      } catch (error) {
        this.$logger.error(error)
      }
    },
    async sendComment() {
      if (this.case_comment_disabled) return

      const text = this.comment.trim()
      if (!text) return

      try {
        this.is_comment_input_disabled = true
        const comment_res = await this.$ohoMemberApi.$post(
          `${this.case_endpoint}/${this.case_id}/comments`,
          {
            text: text,
          }
        )
        this.contact_activity_list.unshift({
          action_by_member_id: this.oho_member,
          created_at: comment_res.created_at,
          text: comment_res.text,
          event_code: "case.comment.created",
        })
        this.comment = ""
      } catch (error) {
        this.$logger.error(error)
      } finally {
        this.is_comment_input_disabled = false
      }
    },
    async handleUploadFileFromDevice(file, file_list = []) {
      try {
        const is_verified = this.beforeFileUpload(file)
        if (!is_verified) return

        const form_data = new FormData()
        form_data.append("file", file.raw)
        const res = await this.$ohoMemberApi.$post(ep.file, form_data)
        this.form.files.push(res)

        this.$message.success("แนบไฟล์สำเร็จ")
      } catch (error) {
        this.$message.error("แนบไฟล์ไม่สำเร็จ")
        const file_index = file_list.findIndex((item) => item.uid === file.uid)
        file_list.splice(file_index, 1)
      }
    },
    selectAttacthmentMethod() {
      if (this.form.files.length >= this.max_files_per_case) {
        this.show_dialog_exceed = true
        return
      }
      if (this.upload_method === "media library") {
        this.showDialogMediaLibrary()
      }
    },
    async showDialogMediaLibrary() {
      this.selected_medias = []
      this.media.page = 1
      await this.getMediaLibraryList()
      this.show_dialog_media_library = true
    },
    async getMediaLibraryList() {
      this.media_library_loading = true

      try {
        const res = await this.$ohoMemberApi.$get(ep.media, {
          params: {
            $limit: this.media.limit,
            $skip: (this.media.page - 1) * this.media.limit,
            "$sort[created_at]": -1,
            "$populate[0][path]": "related_teams",
          },
        })
        const data = res.data.map((d) => {
          d.file_size = prettyBytes(d.size)
          return d
        })
        this.media = { ...this.media, ...res, data }
      } catch (error) {
        // this.$message.error(_.get(error, "response.data"))
      }

      this.media_library_loading = false
    },
    handleExceedFileUpload(files, fileList) {
      this.$message.error("แนบไฟล์ได้ มากสุด 5 ไฟล์")
    },
    beforeFileUpload(file) {
      const is_2m = file.size / 1024 / 1024 <= 10
      this.uploading = true
      if (!is_2m) {
        this.$message.error("ไฟล์ต้องมีขนาดไม่เกิน 10 MB", "error")
        this.uploading = false
      }
      return is_2m
    },
    showUploadDialog() {
      this.upload_dialog = true
    },
    closeUploadDialog() {
      this.$refs.upload.abort()
      this.uploading = false
    },
    removeFile(index) {
      this.form.files.splice(index, 1)
    },
    addFilesAttachment(selected_medias) {
      this.form.files.push(...selected_medias)
      this.$message.success("แนบไฟล์สำเร็จ")
      this.selected_medias = []
      this.show_dialog_media_library = false
    },
    selectMedia(media_list) {
      this.selected_medias = media_list
    },
    isMediaSelectable(row, index) {
      const selected_medias_count = this.selected_medias.length
      const attachments_count = _.size(this.form.files)
      const current_attachments_count =
        selected_medias_count + attachments_count

      if (current_attachments_count === this.max_files_per_reply) {
        const selected_media_index = _.findIndex(this.selected_medias, {
          _id: row._id,
        })
        return selected_media_index >= 0
      } else {
        return true
      }
    },
    async handleFileSuccess(file) {
      try {
        const is_verified = this.beforeFileUpload(file)
        if (!is_verified) return

        const form_data = new FormData()
        form_data.append("file", file.raw)
        const res = await this.$ohoMemberApi.$post(ep.media, form_data, {
          headers: { "content-type": "multipart/form-data" },
        })

        if (res) {
          this.$message({ message: "อัปโหลดไฟล์สำเร็จ", type: "success" })
          this.getMediaLibraryList()
        }
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
      }
      this.uploading = false
    },
    changeMediaPage(page) {
      this.media.page = page
    },
    expandFile(media) {
      if (!["image", "video"].includes(media.type)) return

      this.modal_expand_file = {
        type: media.type,
        source_url: media.url,
        thumbnail_url: media.thumbnail_url,
        name: media.display_name,
      }
    },
    onEditingTag(tag_data = {}) {
      this.tag_edit = true
      const tag_index = this.case_detail.tags_migrate_objectid.findIndex(
        (d) => d._id == tag_data._id
      )
      if (tag_index < 0) return
      this.case_detail.tags_migrate_objectid.splice(tag_index, 1, tag_data)
    },
    onDeleteTag(tag_data) {
      const tag_index = this.case_detail.tags_migrate_objectid.findIndex(
        (d) => d._id == tag_data._id
      )
      if (tag_index < 0) return
      this.case_detail.tags_migrate_objectid.splice(tag_index, 1)
      this.updateCaseListTags()
    },
    onAddTag(tag) {
      this.case_detail.tags_migrate_objectid.push(tag)
      this.updateCaseListTags()
    },
    onRemoveTag(tag) {
      const tag_index = this.case_detail.tags_migrate_objectid.findIndex(
        (d) => d._id == tag._id
      )
      if (tag_index < 0) return
      this.case_detail.tags_migrate_objectid.splice(tag_index, 1)
      this.updateCaseListTags()
    },
    clickCopyClipboard() {
      this.copy_clipboard = true
      setTimeout(() => {
        this.copy_clipboard = false
      }, 3000)
    },
    case_dialog_link() {
      return `${window.location.origin}${this.$route.path}?case=${this.case_id}`
    },
    get_other_involved_member_name(list) {
      return list.map((item) => item.member_id.display_name).join(", ")
    },
  },
}
